<template>
  <div>
    <div class="flix-form-group" v-for="(value, key) in data.data" :key="key">
      <div v-if="key !== $tc('message.note', 1)">
        <h4 class="flix-html-h4">{{ key }}</h4>
        <span v-html="value" v-if="typeof value === 'string'" />
        <ul class="flix-html-ul" v-if="typeof value === 'object'">
          <li class="flix-html-li" v-for="(val, k) in value" :key="k">{{ val }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
      entry: false
    }
  },
  methods: {
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
